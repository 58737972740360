/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFetchCategories } from "@/apis/queryFunctions/categoryFunctions";
import useAuth from "@/hooks/useAuth";
import useTrans from "@/hooks/useTrans";
import useUserInfo from "@/hooks/useUserInfo";
import { useCartContext } from "@/setup/cartProvider";
import { useLocalContext } from "@/setup/locale-provider";
import {
  Avatar,
  Box,
  Burger,
  Grid,
  Group,
  Indicator,
  Menu,
  Modal,
  Popover,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { BsFillPersonFill } from "@react-icons/all-files/bs/BsFillPersonFill";
import { RiShoppingBag2Line } from "@react-icons/all-files/ri/RiShoppingBag2Line";
import { RiShoppingBag3Fill } from "@react-icons/all-files/ri/RiShoppingBag3Fill";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import logo from "public/logo.png";
import logoVT from "public/LandingPage/logoVT.png";
import React, { useState } from "react";
import appendImageUrlFromAPI from "utils/appendImageUrlFromAPI";
import Container from "../Container/Container";
import DrawerMenu from "./components/DrawerMenu";
import Language from "./components/Language";
import MiniCart from "./components/MiniCart";
import SearchBar from "./components/SearchBar";
import useStyles from "./styles";
import useWindowSize from "@/hooks/useWindowSize";
import { openModal } from "@mantine/modals";
import Contact from "@/pages/ca-phe-danh-cho-quan/components/contact/contact";

export default function Header() {
  const { userInfo, isLogin } = useUserInfo();
  const trans = useTrans();
  const context = useCartContext();
  const [openCart, setOpenCart] = React.useState(false);

  const { pathname } = useRouter();
  const isLandingPage =
    pathname === "/ca-phe-danh-cho-quan" ||
    pathname.startsWith("/ca-phe-danh-cho-quan/");

  const [modalOpened, setModalOpened] = useState(false);

  const { logout } = useAuth();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { getLabel } = useLocalContext();
  const { data: categories } = useFetchCategories({
    condition: {},
  });

  const { data: subcategories } = useFetchCategories({
    condition: {},
  });
  const { locale } = useRouter();

  const menuRef = React.useRef();

  // const { hovered: hoveredMainMenu, ref: mainMenuRef } = useHover();
  // const { hovered: hoveredSubMenu, ref: subMenuRef } = useHover();

  // const [dataSubMenu, setDataMenu] = React.useState(false);

  const [opened, setOpened] = React.useState(false);
  const [iconCart, setIconCart] = React.useState(<RiShoppingBag2Line />);

  // const handleMouseOver = (data) => {
  //   // setShowMenu(true);
  //   if (!data) return setDataMenu(false);
  //   setDataMenu(data);
  // };
  // const handleMouseOut = () => {
  //   // setShowMenu(false);
  // };

  // React.useEffect(() => {
  //   if ((hoveredMainMenu || hoveredSubMenu) && dataSubMenu) {
  //     setOverlay(true);
  //   } else {
  //     setOverlay(false);
  //   }
  // }, [hoveredMainMenu, hoveredSubMenu, dataSubMenu, subMenuRef, setOverlay]);

  React.useEffect(() => {
    if (openCart) {
      setIconCart(<RiShoppingBag3Fill />);
    } else {
      setIconCart(<RiShoppingBag2Line />);
    }
  }, [openCart]);

  const { isTablet } = useWindowSize();
  // console.log("open", modalOpened);

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: isLandingPage ? "#118049" : "#fff" }}
    >
      <Container
        fluid={isLandingPage ? true : false}
        style={{
          position: "relative",
          zIndex: 9999,
          background: isLandingPage ? "#118049" : "#fff",
          height: "100%",
          // marginTop: 0,
        }}
      >
        <div
          style={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            // width: "100%",
            height: "100%",
          }}
        >
          {isLandingPage ? (
            <Grid
              align="center"
              justify="space-between"
              sx={{ height: "100%", width: "100%" }}
              gutter={0}
              grow
              bg="#118049"
            >
              <Grid.Col xs={12} md={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.logoWrapperL}>
                    <Link scroll={false} href="/">
                      <a>
                        <Image
                          src={logoVT}
                          alt="Viet Thien"
                          priority
                          layout="responsive"
                          objectFit="contain"
                          width={80}
                          height={50}
                        />
                      </a>
                    </Link>
                  </div>
                  <div className={classes.burgerWrapperL}>
                    <button
                      onClick={() => (window.location.href = "tel:19003258")}
                      style={{
                        cursor: "pointer",
                        padding: "3px 20px",
                        background:
                          "linear-gradient(to top,rgb(171, 32, 32), #8b0000)",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        gap: 7,
                      }}
                    >
                      <img
                        src="/LandingPage/phoneContact.png"
                        width={30}
                        height={30}
                      />
                      <span
                        style={{
                          background:
                            "linear-gradient(to bottom, #ffcc00, #ff9900)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          display: "inline-block",
                          fontWeight: 900,
                          fontSize: 18,
                          fontStyle: "italic",
                        }}
                      >
                        19003258
                      </span>
                    </button>
                    <Burger
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      p={0}
                    />
                  </div>
                </Box>
              </Grid.Col>
              <Grid.Col xs={1} md={6}>
                <div className={`${classes.mainMenu} ${classes.wrapperL}`}>
                  <Link
                    scroll={false}
                    href="/ca-phe-danh-cho-quan"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItemL}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.cafe.title}
                        </Text>
                      </div>
                    </a>
                  </Link>

                  <Link
                    scroll={false}
                    href="/tra-danh-cho-quan"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItemL}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.tea.title}
                        </Text>
                      </div>
                    </a>
                  </Link>

                  <Link
                    scroll={false}
                    href="/nuoc-giai-khat-danh-cho-quan"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItemL}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.drink.title}
                        </Text>
                      </div>
                    </a>
                  </Link>
                  <Link
                    scroll={false}
                    href="/nong-san-danh-cho-quan"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItemL}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.agri.title}
                        </Text>
                      </div>
                    </a>
                  </Link>
                </div>
              </Grid.Col>
              <Grid.Col xs={5} md={4}>
                <div className={classes.rightSideL}>
                  <button
                    onClick={() => (window.location.href = "tel:19003258")}
                    style={{
                      cursor: "pointer",
                      padding: "5px 20px",
                      background:
                        "linear-gradient(to top,rgb(171, 32, 32), #8b0000)",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: 7,
                    }}
                  >
                    <img
                      src="/LandingPage/phoneContact.png"
                      width={30}
                      height={30}
                    />
                    <span
                      style={{
                        background:
                          "linear-gradient(to bottom, #ffcc00, #ff9900)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        display: "inline-block",
                        fontWeight: 900,
                        fontSize: 18,
                        fontStyle: "italic",
                      }}
                    >
                      19003258
                    </span>
                  </button>
                  <button
                    onClick={() => setModalOpened(true)}
                    style={{
                      cursor: "pointer",
                      padding: "5px 20px",
                      background:
                        "linear-gradient(to top,rgb(171, 32, 32), #8b0000)",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      // display: isTablet ? "non" : "",
                    }}
                  >
                    <span
                      style={{
                        background:
                          "linear-gradient(to bottom, #ffcc00, #ff9900)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        display: "inline-block",
                        fontWeight: 900,
                        fontSize: 18,
                        fontStyle: "italic",
                      }}
                    >
                      LIÊN HỆ NGAY
                    </span>
                  </button>
                </div>
              </Grid.Col>
            </Grid>
          ) : (
            <Grid
              align="center"
              justify="space-between"
              sx={{ height: "100%", width: "100%" }}
              gutter={0}
              grow
            >
              <Grid.Col xs={12} md={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.burgerWrapper}>
                    <Burger
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      p={0}
                    />
                  </div>
                  <div className={classes.logoWrapper}>
                    <Link scroll={false} href="/">
                      <a>
                        <Image
                          src={logo}
                          alt="ZeMor Coffee"
                          priority
                          layout="responsive"
                          objectFit="contain"
                          width={80}
                          height={50}
                        />
                      </a>
                    </Link>
                  </div>
                </Box>
              </Grid.Col>
              <Grid.Col xs={1} md={6}>
                <div className={`${classes.mainMenu} ${classes.wrapper}`}>
                  <Link
                    scroll={false}
                    href="/gioi-thieu"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItem}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.about.title}
                        </Text>
                      </div>
                    </a>
                  </Link>
                  <Menu trigger="hover" openDelay={100}>
                    <Menu.Target>
                      <UnstyledButton>
                        <Link
                          scroll={false}
                          href="/mua-hang/san-pham?sort=newest"
                          id="main-menu-item"
                          ref={menuRef}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <a>
                            <div className={classes.menuItem}>
                              <Text size="lg" weight="bold">
                                {trans?.menu.products.title}
                              </Text>
                            </div>
                          </a>
                        </Link>
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {Boolean(categories) &&
                        categories
                          .filter((category) => !category.danh_muc_me)
                          .map((category, i) => (
                            <>
                              {i < 20 && (
                                <Menu.Item>
                                  <Menu
                                    trigger="hover"
                                    openDelay={100}
                                    position="right-start"
                                  >
                                    <Menu.Target sx={{ width: "100%" }}>
                                      <UnstyledButton>
                                        <Link
                                          href={`/danh-muc-san-pham/${category?.slug}?sort=all`}
                                          passHref
                                          key={i}
                                        >
                                          <a>
                                            <Menu.Item closeMenuOnClick>
                                              {locale === "vi"
                                                ? category?.ten_danh_muc
                                                : category?.ten_danh_muc_eng}
                                            </Menu.Item>
                                          </a>
                                        </Link>
                                      </UnstyledButton>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                      {Boolean(subcategories) &&
                                        subcategories
                                          .filter(
                                            (el) =>
                                              el.danh_muc_me ==
                                              category.ma_danh_muc
                                          )
                                          .map((subcategory, i) => (
                                            <>
                                              {
                                                <Link
                                                  href={`/danh-muc-san-pham/${category?.slug}?sort=${subcategory?.ma_danh_muc}`}
                                                  passHref
                                                  key={i}
                                                >
                                                  <a>
                                                    <Menu.Item closeMenuOnClick>
                                                      {locale === "vi"
                                                        ? subcategory?.ten_danh_muc
                                                        : subcategory?.ten_danh_muc_eng}
                                                    </Menu.Item>
                                                  </a>
                                                </Link>
                                              }
                                            </>
                                          ))}
                                    </Menu.Dropdown>
                                  </Menu>
                                </Menu.Item>
                              )}
                            </>
                          ))}
                    </Menu.Dropdown>
                  </Menu>
                  <Link
                    scroll={false}
                    href="/mua-hang/san-pham?filterBy=promotion"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItem}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.promotion.title}
                        </Text>
                      </div>
                    </a>
                  </Link>
                  <Menu trigger="hover" openDelay={100}>
                    <Menu.Target>
                      <UnstyledButton>
                        <Link
                          scroll={false}
                          href="/tin-tuc"
                          id="main-menu-item"
                          ref={menuRef}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <a>
                            <div className={classes.menuItem}>
                              <Text size="lg" weight="bold">
                                {trans?.menu.news.title}
                              </Text>
                            </div>
                          </a>
                        </Link>
                      </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Link href={`/tin-tuc`} passHref>
                        <a>
                          <Menu.Item closeMenuOnClick>
                            {locale === "vi" ? "Tin Tức" : "News"}
                          </Menu.Item>
                        </a>
                      </Link>
                      <Link href={`/tin-tuc`} passHref>
                        <a>
                          <Menu.Item closeMenuOnClick>
                            {locale === "vi" ? "Tài Liệu" : "Documents"}
                          </Menu.Item>
                        </a>
                      </Link>
                    </Menu.Dropdown>
                  </Menu>

                  <Link
                    scroll={false}
                    href="/lien-he"
                    id="main-menu-item"
                    ref={menuRef}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <a>
                      <div className={classes.menuItem}>
                        <Text size="lg" weight="bold">
                          {trans?.menu.contact.title}
                        </Text>
                      </div>
                    </a>
                  </Link>
                </div>
              </Grid.Col>
              <Grid.Col xs={5} md={4}>
                <div className={classes.rightSide}>
                  <div className={classes.optionsWrapper}>
                    <SearchBar />

                    <Popover
                      width={340}
                      position="bottom"
                      withArrow
                      shadow="md"
                      arrowOffset={50}
                      styles={{
                        arrow: { left: "auto !important", right: "15px" },
                        dropdown: {
                          left: "auto !important",
                          right: 20,
                        },
                      }}
                      onOpen={() => setIconCart(<RiShoppingBag3Fill />)}
                      onClose={() => setIconCart(<RiShoppingBag2Line />)}
                      onChange={setOpenCart}
                      opened={openCart}
                    >
                      <Popover.Target>
                        <Box
                          sx={{
                            fontSize: "20px",
                            margin: "10px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenCart((o) => !o)}
                        >
                          <Indicator
                            label={
                              <Text color="#fff">
                                {context?.cart?.length || 0}
                              </Text>
                            }
                            size={20}
                            radius="50%"
                            color={theme.colors.main[0]}
                          >
                            <div>{iconCart}</div>
                          </Indicator>
                        </Box>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <MiniCart onClose={() => setOpenCart(false)} />
                      </Popover.Dropdown>
                    </Popover>
                    <Language />
                    <div className={classes.authWrapper}>
                      {isLogin ? (
                        <div>
                          <Menu shadow="md" width={200}>
                            <Menu.Target>
                              {userInfo && (
                                <Group sx={{ cursor: "pointer" }}>
                                  <Avatar
                                    src={appendImageUrlFromAPI({
                                      src: userInfo?.picture,
                                    })}
                                    alt={userInfo?.name}
                                    radius="xl"
                                    size="sm"
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text size="sm" weight="500">
                                      {userInfo?.name}
                                    </Text>
                                  </div>
                                </Group>
                              )}
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Label>
                                {trans.profile.manager.title}
                              </Menu.Label>
                              <Link
                                scroll={false}
                                href="/user-profile/my-profile"
                              >
                                <a>
                                  <Menu.Item>
                                    {trans.profile.manager.info}
                                  </Menu.Item>
                                </a>
                              </Link>
                              <Link
                                scroll={false}
                                href="/user-profile/address-book"
                              >
                                <a>
                                  <Menu.Item>
                                    {trans.profile.manager.address}
                                  </Menu.Item>
                                </a>
                              </Link>
                              <Link
                                scroll={false}
                                href="/user-profile/purchased-return"
                              >
                                <a>
                                  <Menu.Item>
                                    {trans.profile.manager.purchase}
                                  </Menu.Item>
                                </a>
                              </Link>
                              <Link
                                scroll={false}
                                href="/user-profile/liked-product"
                              >
                                <a>
                                  <Menu.Item>
                                    {trans.profile.manager.liked}
                                  </Menu.Item>
                                </a>
                              </Link>

                              <Menu.Divider />

                              <Menu.Label>
                                {trans.profile.manager.adjust}
                              </Menu.Label>
                              <Menu.Item
                                onClick={() => logout(userInfo?.token)}
                              >
                                <div>{trans.profile.manager.logout}</div>
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </div>
                      ) : (
                        <div>
                          <Link href="/dang-nhap">
                            <Box>
                              <BsFillPersonFill size={20} />
                            </Box>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Grid.Col>
            </Grid>
          )}
        </div>
      </Container>

      <DrawerMenu opened={opened} setOpened={setOpened} />
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        // padding={0}
        size="50%"
        overlayProps={{
          color: "rgba(0, 0, 0, 0.5)",
        }}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        styles={(theme) => ({
          header: {
            display: "none",
          },
          body: {
            display: "flex",
            justifyContent: "center",
            // aspectRatio: "1.77",
            width: "100%",
          },
          modal: {
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
              width: "100%",
            },
          },
        })}
      >
        <Contact />
      </Modal>
    </div>
  );
}

import { postContact } from "@/apis/queryFunctions/contact";
import useTrans from "@/hooks/useTrans";
import useWindowSize from "@/hooks/useWindowSize";
import {
  Button,
  Card,
  Group,
  MediaQuery,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMutation } from "@tanstack/react-query";

export default function Contact({ bg }) {
  const trans = useTrans();
  const { isTablet } = useWindowSize();
  const form = useForm({
    initialValues: {
      ho_ten: "",
      dien_thoai: "",
      email: "",
      dia_chi: "",
      noi_dung: "",
    },
    validate: {
      ho_ten: (value) =>
        value.length > 0 ? null : "Vui lòng nhập họ tên của quý khách",
      email: (value) =>
        value.length > 0
          ? /^\S+@\S+$/.test(value)
            ? null
            : "Email không hợp lệ"
          : "Vui lòng nhập email để cửa hàng liên hệ với quý khách",
      //   dien_thoai: (value) =>
      //     value.length > 0
      //       ? /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/.test(
      //           value
      //         )
      //         ? null
      //         : "Số điện thoại không hợp lệ"
      //       : "Vui lòng nhập số điện thoại của quý khách",
      // noi_dung: (value) => (value.length > 0 ? null : "Vui lòng nhập nội dung"),
    },
  });

  const contactMutation = useMutation(postContact, {
    onSuccess: (rs) => {
      if (rs._id) {
        appAlert({ type: "success", message: "Gửi thông tin thành công" });
      } else {
        appAlert({ message: "Gửi thông tin không thành công" });
      }
      form.reset();
    },
  });

  // const handleSubmit = () => {
  //   contactMutation.mutate({ ...form.values });
  // };
  const handleSubmit = () => {
    // Kiểm tra nếu không có nội dung, gán giá trị mặc định
    const formData = {
      ...form.values,
      noi_dung:
        form.values.noi_dung.trim() === ""
          ? "liên hệ từ web zemor"
          : form.values.noi_dung,
    };

    contactMutation.mutate(formData);
  };

  return (
    <Card
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
      bg={bg || "transparent"}
      h="100%"
      w={isTablet ? "90%" : "70%"}
      sx={{
        position: "relative",
        boxShadow:
          "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.15)",
        "&:hover": {
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "30px",
          height: "30px",
          borderTop: "4px solid black",
          borderLeft: "4px solid black",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "30px",
          height: "30px",
          borderTop: "4px solid black",
          borderRight: "4px solid black",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "30px",
          height: "30px",
          borderBottom: "4px solid black",
          borderRight: "4px solid black",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "30px",
          height: "30px",
          borderBottom: "4px solid black",
          borderLeft: "4px solid black",
        }}
      />

      <MediaQuery smallerThan="md" order={4}>
        <Title order={4} align="center" transform="uppercase" py="lg" fz="md">
          {trans?.contact.label}
        </Title>
      </MediaQuery>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          //   label={trans?.contact.form.fullName}
          withAsterisk
          size={isTablet ? "xs" : "md"}
          placeholder={trans?.contact.placeholder2.fullName}
          {...form.getInputProps("ho_ten")}
          mb={10}
        />
        <TextInput
          //   label={trans?.contact.form.address}
          size={isTablet ? "xs" : "md"}
          placeholder={trans?.contact.placeholder2.address}
          {...form.getInputProps("dia_chi")}
          mb={10}
        />
        <TextInput
          //   label={trans?.contact.form.phone}
          size={isTablet ? "xs" : "md"}
          placeholder={trans?.contact.placeholder2.phone}
          {...form.getInputProps("dien_thoai")}
          mb={10}
        />
        <TextInput
          //   label={trans?.contact.form.email}
          withAsterisk
          size={isTablet ? "xs" : "md"}
          placeholder={trans?.contact.placeholder2.email}
          {...form.getInputProps("email")}
          mb={10}
        />
        {/* <Textarea
          //   label={trans?.contact.form.content}
          placeholder={trans?.contact.placeholder2.content}
          withAsterisk
          size="md"
          autosize
          minRows={3}
          {...form.getInputProps("noi_dung")}
        /> */}
        <Group position="center">
          <Button
            type="submit"
            mt="sm"
            fullWidth
            sx={{
              background: "linear-gradient(to top, #8B0000, #FF0000)", // Đỏ sậm sang đỏ tươi
              color: "#fff",
              "&:hover": {
                background: "linear-gradient(to top,rgb(180, 39, 39), #FF6347)", // Màu hover sáng hơn
              },
            }}
          >
            <Text weight="500" transform="uppercase">
              {trans?.contact.form.button}
            </Text>
          </Button>
        </Group>
      </form>
    </Card>
  );
}
